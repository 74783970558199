<template>
  <div>
    <b-card
      :img-src="`https://picsum.photos/seed/${item.identifier}/400/100`"
      img-alt="Image"
      img-top
      img-width="400"
      img-height="100"
      footer-class="pb-0 px-0"
    >
      <b-card-text class="d-block">
        <h2 :id="getID('transmission-name')" class="transition-name-container">
          {{transmissionName }}
        </h2> 

      </b-card-text>
        <b-row no-gutters class="d-flex justify-content-between">
          <b-col class="text-secondary">

            <span class="d-block mb-25">
              <b-badge variant="light-success" v-if="item.active">
                <span class="middleware-status active mr-50"/>
                <span class="small font-weight-bolder text-uppercase">{{$t('common.terms.active')}}</span>
              </b-badge>

              <b-badge variant="light-danger" v-else> 
                <span class="middleware-status inactive mr-50"/>
                <span class="small font-weight-bolder text-uppercase">{{$t('common.terms.inactive')}}</span>
              </b-badge>
            </span>

            <span class="mt-2 smaller-text">
              {{ $t('common.terms.created') }}
              <span class="font-weight-bold">
                {{ item.created_at }} 
              </span>
            </span>
          </b-col>

          <b-col class="d-flex justify-content-end">
            <div class="d-flex my-25">
              <div>
                <b-button 
                  variant="outline-secondary" 
                  class="mx-50 px-50"  
                  :to="{ name: 'middlewares', params: { transmissionID: item.id }  }"
                >
                  <feather-icon class="" icon="GitPullRequestIcon"/>
                </b-button>
              </div>
              <div>
                <b-button 
                  variant="success" 
                  class="d-block"
                  :to="{ name: 'transmission-dashboard', params: { transmissionID: item.id }  }"
                > 
                  {{ $t('common.terms.access')}}
                </b-button>
              </div>
            </div>
          </b-col>
          
        </b-row>

        <!-- <div class="sparser-letters">
          <b-badge v-if="item.active" variant="light-success" class="w-100 square rounded" >ACTIVE</b-badge>
          <b-badge v-else variant="light-danger" class="w-100 square rounded">INACTIVE</b-badge>
        </div> -->

    </b-card>
    <b-tooltip :target="getID('transmission-name')">
      <span>{{ this.item.name }}</span>
    </b-tooltip>
    
  </div>
</template>

<script>
import { 
  BCard,
  BCardText,
  BButton, 
  BRow, 
  BCol, 
  BTooltip, 
  BLink, 
  BBadge,
  BAvatar
} from 'bootstrap-vue';

import VueContext from 'vue-context'
import { makeToast } from '@/layouts/components/Popups'
import { v4 as uuidv4 } from "uuid";

  export default {
    components: {
      BCard, 
      BCardText,
      BButton,
      BRow,
      BCol,
      BTooltip,
      VueContext,
      BLink,
      BBadge,
      BAvatar
    },
    data() {
      return {
        uuidMap: {},
      }
    },  
    props: {
      value: {
        type: Object,
        default: null,
      },
    },
    computed: {
      item: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      },
      transmissionName() {
        return this.item.name.length < 27 ? this.item.name : `${this.item.name.substring(0,28)}...`
      },
      transmissionOptions() {
        return [
          { title: "Go to Middlewares", icon: "GitPullRequestIcon", action: "middlewares", params: { transmissionID: this.item.id }  }
        ]
      }
    },
    methods: {
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
    }
  }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.vue-context-menu{
  position: absolute;
}

</style>

<style lang="scss" scoped>

.sparser-letters{
  letter-spacing: 2px !important;
}
.transition-name-container{
  max-height: 29px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.middleware-status{
  border-radius: 100px;
  display: inline-block;
  padding: 4px;

  &.active{
    background-color: var(--success);
  }
  &.inactive{
    background-color: var(--danger);
  }
}

.smaller-text{
  font-size: 11px;
}


</style>