import { render, staticRenderFns } from "./TransmissionCard.vue?vue&type=template&id=5d57642c&scoped=true&"
import script from "./TransmissionCard.vue?vue&type=script&lang=js&"
export * from "./TransmissionCard.vue?vue&type=script&lang=js&"
import style0 from "./TransmissionCard.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./TransmissionCard.vue?vue&type=style&index=1&id=5d57642c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d57642c",
  null
  
)

export default component.exports