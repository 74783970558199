<template>
  <div>
    <div class="d-flex justify-content-between mb-2 position-relative" >      
      <b-col>
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            :placeholder="$t('common.terms.search')"
            v-model="searchFilter"
            type="search"
          />
        </b-input-group>
      </b-col>
      <b-button
        v-if="$can('create')"
        variant="primary"
        class="mr-1"
        @click="$router.push({ name: 'transmission'})"
      >
        <feather-icon
          icon="PlusIcon"
        />
        {{ $t('middleware.carousel.add_transmission')}}
      </b-button>

      <b-button v-if="isUserGod()" class="create-transmission-report py-50" variant="outline-secondary" @click="$refs['modal-generate-report'].show()">
        <b-icon icon="clipboard-data" class="mr-25"/> Generate Organization Report
      </b-button>

    </div>
    <div class="mt-1 overflow-hidden" v-if="items">
      <b-row class="transmission-card-wrapper">
        <b-col 
          cols="12" md="6" lg="4" xl="3" 
          v-for="key in itemsShown" 
          :key="`transmission-card-${filteredItems[key - 1].identifier}`" 
          class=" animate__animated animate__faster  animate__fadeIn"
        >
          <transmission-card
            :class="filteredItems[key - 1].active ? '' : 'opacity-50 opacity-100-hover'"
            v-model="filteredItems[key - 1]"
          />
        </b-col>
      </b-row>

      <div class="search-more"/> <!-- Element that Triggers loading more Transmissions when it appears on screen -->

      <div v-if="filteredItems && (filteredItems.length <= 0 && items.length > 0)">
        <h5 class="text-center text-secondary mt-5">
          {{ $t('middleware.carousel.none_matched')}}
        </h5>
      </div>

      <div v-if="items.length == 0">
        <h5 class="text-center text-secondary mt-5">
          {{ $t('middleware.carousel.no_transmissions_label')}}
        </h5>
      </div>

      <div align="center" class="d-flex justify-content-center align-items-center" v-if="!(pageIdx < 2) || !(items.length != maxItemsPerPage)">
        <b-button class="round p-50" variant="outline-secondary" :disabled="pageIdx < 2" @click="navigationChange(-1)">
            <b-icon icon="chevron-left" class="px-25" variant="dark"/>
        </b-button>
        <b-avatar variant="purple" size="30" class="mx-1">{{pageIdx}}</b-avatar>
        <b-button class="round p-50" variant="outline-secondary" :disabled="items.length != maxItemsPerPage" @click="navigationChange(+1)">
          <b-icon icon="chevron-right" class="px-25" variant="dark"/>
        </b-button>
      </div>

    </div>

    <b-col class="mt-1" v-else>
      <b-row>
          <b-col v-for="i in 8" :key="`trasmission-card-skeleton-${i}`" md="6" lg="4" xl="3">
            <b-card no-body img-top>
              <b-skeleton-img card-img="top" aspect="4:1"></b-skeleton-img>
              <b-card-body class="p-2">
                <b-card-text class="d-block">
                  <b-skeleton height="28px"/>
                </b-card-text>
              </b-card-body>
              <template #footer>
                <b-row class="d-flex justify-content-between">
                  <b-col>
                    <b-skeleton height="30px"/>
                  </b-col>
                  <b-col class="d-flex justify-content-end">
                    <b-skeleton type="button" height="30px"/>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-col>
        </b-row>
    </b-col>

    <organization-report-modal :transmissions="items" ref="modal-generate-report" v-if="Array.isArray(items)"/>

    <user-hint class="mb-3"/>
  </div>
</template>


<script>

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Carousel, Slide } from 'vue-carousel';
import {
  BCard,
  BCardText,
  BCardBody,
  BLink,
  BButton,
  BCol,
  BRow,
  BSkeleton,
  BSkeletonImg,
  BFormInput,
  BInputGroup, 
  BInputGroupPrepend,
  BBadge,
  BPagination,
  BAvatar
} from "bootstrap-vue";
import { makeToast } from "@/layouts/components/Popups.js";
import EventBus from "@/custom/eventBus";
import vSelect from "vue-select";
import LoadingOverlay from "@/layouts/components/LoadingOverlay";
import { mapGetters } from 'vuex'
import TransmissionCard from '@/layouts/components/Transmission/TransmissionCard'
import OrganizationReportModal from '@/views/pages/middleware/MiddlewareManagement/Components/Modals/OrganizationReportModal.vue'
import UserHint from "@/views/pages/middleware/UserHint.vue"


export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    LoadingOverlay,
    BSkeleton,
    Carousel,
    Slide,
    BSkeletonImg,
    BCardBody,
    TransmissionCard,
    BFormInput,
    BInputGroup, 
    BInputGroupPrepend,
    BBadge,
    BPagination,
    BAvatar,
    OrganizationReportModal,
    UserHint,
  },
  data() {
    return {
      selectedTransmissionGroup: null,
      items: null,
      version: 0,
      currentPage: 1,
      searchFilter: "",
      pageIdx: 1,
      maxItemsPerPage: 50,
    };
  },
  mounted() {
    this.init();
    EventBus.$on("loadTransmissionGroupSelection", () => {
      this.init();
    });
  },
  beforeDestroy() {
    EventBus.$off("loadTransmissionGroupSelection")
  },
  computed: {
    ...mapGetters(['getTransmissions', 'getSelectedOrganization', 'getUserOrgs', 'getAbility']),
    itemsPerPage() {
      let current_breakpoint = this.$store.getters['app/currentBreakPoint'];

      return {
        "md": 8, 
        "lg": 12, 
        "xl": 16,
      }[current_breakpoint] || 4;
    },
    itemsShown() {
      if(!this.items || !this.filteredItems) {
        return 0;
      }

      return Math.min(this.itemsPerPage * this.currentPage, this.filteredItems.length);
    },
    hasNextPage() {
      if(!this.items || !this.filteredItems) {
        return this.items
      }

      return this.currentPage < Math.ceil(this.filteredItems.length / this.itemsPerPage)
    },
    filteredItems() {
      let r = this.items.filter((el) => {
        return el.name.toLowerCase().indexOf(this.searchFilter.toLowerCase()) > -1;
      });

      const inactive = r.filter(el => !el.active)
      const active = r.filter(el => el.active)
      
      return [...active, ...inactive];
    },
  },
  methods: {
    init() {
      this.items = null,
      this.selectedTransmissionGroup = null;
      this.fetchTransmissionsWhenAvailable();

      
      if (this.items){
        this.$nextTick(()=>{
          this.scroll()
        })
      }
      // this.fetchTransmissionGroupByOrganization();
    },
    handleSubmit() {
      this.$refs.transmissionGroupSelection.validate().then((success) => {
        if (success) {
          localStorage.setItem(
            "selectedTransmissionGroup",
            this.selectedTransmissionGroup
          );
          this.$router
            .push({ name: "transmission-dashboard" })
        } else {
          makeToast({
            title: this.$t("middleware.toast.home.error.title"),
            text: this.$t("middleware.toast.home.error.message"),
            variant: "danger",
            icon: "XIcon",
          });
        }
      });
    },
    fetchTransmissionGroupByOrganization(forceFetch = false) {
      if (forceFetch){
        this.items = null
      }
      if (this.getTransmissions.items && this.getSelectedOrganization == this.getTransmissions.orgID && !forceFetch) {
        this.items = this.getTransmissions.items
      } else {
          this.$store
            .dispatch("getTransmissionByOrganization", {filter: {order: 'id DESC' , per_page: this.maxItemsPerPage, page: this.pageIdx}})
            .then((response) => {
              if (response.length) {
                this.items = response.sort((a, b) => {
                  return a.active < b.active
                });
                this.$nextTick(() => {
                  this.scroll();
                });
                response = null;
                // this.transmissionGroupSetted();
                // this.$store.dispatch("setLoadingState", false);
              } else {
                this.items = [];
                // this.$store.dispatch("setLoadingState", false);
              }
            })
            .catch((error) => {
              console.error(error);

              makeToast({
                title: this.$t(
                  "middleware.toast.transmission.fetch.error.title"
                ),
                text: this.$t(
                  "middleware.toast.transmission.fetch.error.message"
                ),
                variant: "danger",
                icon: "XIcon",
              });
              // this.$store.dispatch("setLoadingState", false);
            });
        // });
      }
    },
    fetchTransmissionsWhenAvailable(){
      if (localStorage.getItem('selectedOrganization') && this.getUserOrgs){
        this.fetchTransmissionGroupByOrganization();
        
        return
      } else {
        setTimeout(() => {
          this.fetchTransmissionsWhenAvailable();
        }, 100);
      }
      
    },
    goNextPage() {
      this.currentPage++
    },
    isScrolledIntoView(el) {
      let rect = el.getBoundingClientRect();
      let elemTop = rect.top;
      let elemBottom = rect.bottom;

      let isVisible = elemTop < window.innerHeight && elemBottom >= 0;

      return isVisible;
    },
    scroll() {
      window.onscroll = () => {
        let scrolledTo = document.querySelector(".search-more");

        if (
          this.hasNextPage &&
          scrolledTo &&
          this.isScrolledIntoView(scrolledTo)
        ) {
          this.goNextPage();
        }
      };
    },
    isUserGod() {
      if (this.getAbility) {
        return this.getAbility.id === 666;
      } else {
        return false
      }
    },
    navigationChange(mod){
      this.pageIdx = this.pageIdx+mod;
      this.fetchTransmissionGroupByOrganization(true)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.opacity-50 {
  opacity: 0.4;
}

.opacity-100-hover {
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
}


.create-transmission-report{
  position: absolute;
  top: -50px;
  right: 15px;
}

</style>
