<template>
  <div class="outer-container" :class=" isActive ? 'on' : 'off'">
    
    <b-button @click="showPopup()" v-if="showButton" class="p-0 m-0" variant="none">
      <slot name="btn-content"></slot>
    </b-button>

    <div class="popup-container">

      <div class="popup-body" @click="cancelAutoClose()"> 
        <div class="d-flex align-items-end justify-content-between mb-25 w-100">
          <div class="d-flex align-items-center">
            <b-icon icon="question-octagon" class="mr-75" scale="1.2" variant="success"/>
            <h5 class="m-0">Did you know?</h5>
          </div>
          
          <b-button class="m-0 p-0" variant="none" @click="closePopup()">
            <b-icon icon="x" scale="1.3" variant="danger"/>
          </b-button>
        </div>

        <div class="popup-text px-25" v-if="shownHint">
          <template v-for="segment in shownHint.content">
            <span :class="'text-'+getKey(segment)">
              {{getValue(segment)}}
            </span>
          </template>
        </div>
        
        <!-- <div class="small text-muted" align="right" v-if="shownHint">
           id - {{shownHint.id}}
        </div> -->
      </div>


    </div>

  </div>
</template>

<script>
import { 
  BCol,
  BRow,
  BIcon,
  BSkeleton,
  BCollapse,
  BTabs,
  BTab,
  BButton,
  BFormInput,
  BFormCheckbox,
 } from 'bootstrap-vue';
import anime from 'animejs';
import userHints from '@/custom/class/Enum/UserHints.js';

export default {
  components: {  
    BCol,
    BRow,
    BIcon,
    BSkeleton,
    BCollapse,
    BTabs,
    BTab,
    BButton,
    BFormInput,
    BFormCheckbox,
  },
  data() {
    return {
      shownHint: undefined,
      popupAnimation: undefined,
      reversed: false,
      autoclose: undefined,
      isActive: false,
    }
  },
  props: {
    randomizeAppearance: {
      type: Boolean,
      default: true,
    },
    showButton:{
      type: Boolean,
      default: false,
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init(){
      
      if (this.randomizeAppearance){
        if (Math.random() > 0.8){
          this.showPopup();
        }
      } else {
        this.showPopup();
      }

    },
    getValue(itm){
      return Object.values(itm)[0]
    },
    getKey(itm){
      return Object.keys(itm)[0]
    },
    showPopup(){
      if (!this.popupAnimation){
        this.isActive = true;
        this.shownHint = new userHints().random;
        
        this.popupAnimation = anime({
          targets:".popup-container",
          right: ["-150px","20px"],
          opacity: [0, 1],
          duration: 800,
          easing: 'easeInOutCubic'
        })
        this.autoclose = setTimeout(() => {
          this.closePopup();
        }, 7000);  
      }
    },
    closePopup(){
      if (this.popupAnimation && !this.reversed){
        this.reversed = true
        this.popupAnimation.reverse()
        this.popupAnimation.restart()
        this.popupAnimation.complete = ()=>{
          this.popupAnimation = undefined
          this.reversed = false
          this.isActive = false
        }
      }
    },
    cancelAutoClose(){
      if (this.autoclose){
        clearTimeout(this.autoclose)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.popup-container{
  --lowered-favorite: rgba(207, 157, 41, 0.4656);
  position: fixed;
  right: -150px;
  bottom: 20px;
  opacity: 0;
  z-index: 3000;
  pointer-events: none;
  
  .popup-body{
    pointer-events: all;
    height: 100px;
    width: fit-content;
    max-width: 400px;
    min-width: 250px;
    height: fit-content;
    min-height: 60px;
    border-radius: 8px;
    padding: 10px;
    background-color: #0b0e18;
    color: var(--light);
  }

  .popup-text{
    font-size: 13px;
  }  
}


.outer-container{
  &.on{
    display: block;
  } 
  &.off{
    display: none;
  }

}
</style>