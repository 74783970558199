

export function shuffleArray(array){

  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array;
}


export function getRandomItem(list, amount=1){

  let r = structuredClone(list);
  r = shuffleArray(r)
  if (amount != 1){
    let l = []
    for (let i=0; i < amount; i++){
      l.push(r[i])
    }
    return l;

  } else {
    return r[0];
  }

}
